import React from 'react';

export type HeaderProps = {};

const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="w-full h-10 bg-blue-300 flex flex-row items-center justify-between px-4">
      <div className="w-40">
        <div className="text-gray-700 font-black">
          INNOSMASH
        </div>
      </div>
      <div className="w-full flex flex-row justify-end items-center">
        About
      </div>
    </div>
  );
}

export default Header;