import React, { useState } from 'react';
import './style.css';
import { AiOutlineMobile, AiOutlineDesktop, AiOutlinePartition } from 'react-icons/ai';
import { MdLiveTv } from 'react-icons/md';
export type HomePageProps = {};

const HomePage: React.FC<HomePageProps> = () => {
  const [contactSubject, setContactSubject] = useState<string>('');
  const [contactBody, setContactBody] = useState<string>('');

  const sendContact = async () => {
    console.log("form", contactSubject, contactBody);
  }

  return (
    <div className="w-full h-auto flex flex-col justify-start items-center">
      <div className="h-full w-full bg-blue-200 flex md:flex-row flex-col justify-center items-center HomePageJumbo">
        <div className="w-full flex items-center justify-center card hero-bg">
          <h1 className='md:text-4xl text-xl text-center'>Transforming Ideas into Digital Solutions</h1>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <h1 className="md:text-2xl text-xl md:m-12 m-8">Innovative Development Solutions Pairing A.I. and E-Commerce</h1>
      </div>
      <div className="w-full md:px-20 flex flex-row flex-wrap md:mb-8 mb-4 justify-center ShowCaseGrid">
        <div className="w-full max-w-xs flex flex-col items-center item">
          <h1 className="text-xl">Web Development</h1>
          <AiOutlineDesktop className="mt-6" size={72} color="lightblue" />
        </div>
        <div className="w-full max-w-xs flex flex-col items-center item">
          <h1 className="text-xl">Mobile Applications</h1>
          <AiOutlineMobile className="mt-6" size={72} color="lightblue" />
        </div>
        <div className="w-full max-w-xs flex flex-col items-center item">
          <h1 className="text-xl">Artificial Intelligence</h1>
          <AiOutlinePartition className="mt-6" size={72} color="lightblue" />
        </div>
        <div className="w-full max-w-xs flex flex-col items-center item">
          <h1 className="text-xl">Live Video</h1>
          <MdLiveTv className="mt-6" size={72} color="lightblue" />
        </div>
      </div>
      <div className="w-full flex flex-row HomeAbout my-8">
        <div className="w-full p-4 max-w-5xl m-auto">
          <h2 className="text-4xl heading">Our Mission:</h2>
          <p className='mt-4'>Our mission at Innosmash is to empower businesses of all sizes to reach their full potential through innovative and intuitive web and mobile technology solutions. We strive to understand the unique needs and goals of each of our clients and to deliver custom-tailored applications that exceed their expectations. Our team of experienced developers is dedicated to staying on the cutting edge of technology and using their expertise to bring our clients' visions to life. Our ultimate goal is to help our clients succeed in an increasingly digital world by providing them with the tools and support they need to thrive.</p>
        </div>
      </div>
      <div className="w-full flex flex-col HomeAbout my-8">
        <div className="w-full p-4 max-w-5xl m-auto flex flex-col">
          <h2 className="text-4xl heading">Contact</h2>
          <div className="w-full flex flex-row">
            <div className="w-1/3 mt-8">
              <h3 className='font-bold text-xl'>Innosmash LLC</h3>
              <p>1 Chisholm Trail</p>
              <p>Bldg 1, Suite 450</p>
              <p>Round Rock, TX 78681</p>
            </div>        
            <div className="w-2/3 flex flex-col mt-8">
              <input type="text" className="w-full outline-none border-2 border-blue-200 h-14 text-lg rounded-lg px-4" value={contactSubject} onChange={(e) => setContactSubject(e.target.value)} placeholder="Subject" />
              <textarea className="outline-none border-2 border-blue-200 text-lg rounded-lg mt-6 p-4" onChange={(e) => setContactBody(e.target.value)} placeholder="Body">{contactBody}</textarea>
              <button className="w-full outline-none border-2 border-blue-200 mt-6 rounded-lg py-2" onClick={sendContact}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;