import React from 'react';

export type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  return (
    <>
    <div className="w-full h-auto flex flex-row justify-start items-center bg-blue-600" style={{minHeight: '200px'}}>
      
    </div>
    <div className="w-full h-10 flex justify-center items-center bg-gray-900">
      <small className='text-white'>Site / Logo © INNOSMASH {new Date().getFullYear()}</small>
    </div>
    </>
  );
}

export default Footer;