import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Footer from "./components/footer";

import Header from './components/header';
import HomePage from "./containers/home";

function App() {
  return (
    <Router>
      <div className="w-full h-auto">
        <Header />
          <div className="min-h-screen">
            <Switch>
              <Route path="/">
                <HomePage />
              </Route>
            </Switch>
          </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
